.main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  width: 100%;
  max-width: 1500px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 3rem 9rem;
}

@media (max-width: 1007px) {
  .app {
    padding: 0 4rem;
  }
}

@media (max-width: 819px) {
  .app {
    flex-direction: column;
    justify-content: center;
    gap: 3rem;
  }
}

@media (max-width: 600px) {
  .app {
    padding: 3rem 2rem;
  }
}

@media (max-width: 400px) {
  .app {
    padding: 2rem 1rem;
  }
}

.blur {
  width: 200px;
  height: 200px;
  position: absolute;
  filter: blur(90px);
  background: #3c7a2f;
  left: 50%;
}

.img_container {
  width: 40%;
  position: relative;
}

.img_container > img {
  width: 100%;
}

@media (max-width: 819px) {
  .img_container {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .img_container {
    width: 90%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1,
p {
  font-family: "Roboto", sans-serif;
  color: rgba(225, 255, 255, 0.8);
  letter-spacing: 0.8px;
  font-size: 50px;
}

@media (max-width: 800px) {
  h1 {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 30px;
  }
}

p {
  font-size: 16px;
  width: 70%;
  line-height: 25px;
  margin-top: 10px;
}

@media (max-width: 500px) {
  p {
    width: 100%;
  }
}

.text_con {
  width: 50%;
}

@media (max-width: 819px) {
  .text_con {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
}

body {
  background: #142b0e;
}

.header {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 1rem 7rem;
  z-index: 9999;
}

.header > img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

@media (max-width: 1007px) {
  .header {
    padding: 1rem 4rem;
  }
}

@media (max-width: 600px) {
  .header {
    padding: 1rem 2rem;
  }
}

@media (max-width: 400px) {
  .header {
    padding: 1rem 1rem;
  }
}
